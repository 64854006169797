'use strict';

// Constructor
var Record = function() {
    var countdown = $('.countdown'),
        mainStage = $('main'),
        scene = $('.scene'),
        counter = 5,
        video = $('.embed iframe'),
        videoSrc = $('.embed iframe').attr('src'),
        backdrop = $('.backdrop'),
        embed = $('.embed'),
        timer = $('.timer'),
        seconds = $('.seconds'),
        minutes = $('.minutes'),
        count = 0,
        minuteCount = 0,
        stop = $('.-stop'),
        camera = $('.camera'),
        startCron,
        load = $('.upload'),
        loadCount = 0,
        loadPercent = $('.upload .number'),
        loadMessage = $('.upload .message'),
        messages = [
            'Estamos procesando tu video, tranquilein John Wayne',
            'Ya queda poco, aprovecha de ir por una Kem Xtreme',
            'Recuerda compartir tu video usando #desafioKX',
            'Ohh, ¡Cómo agarré tanto vuelo!',
            'Felicidades, tu video está en moderación, ya pronto lo podrás ver aquí mismo.'
        ],
        loadBar = $('.upload .load');

    function chronometer() {
        startCron = setInterval(function() {
            count++;
            if (count < 60) {
                if (count < 10) {
                    seconds.html('0' + count);
                } else {
                    seconds.html(count);
                }
            } else if (count === 60) {
                count = 0;
                minuteCount++;
                seconds.html('0' + count);
                if (minuteCount < 10) {
                    minutes.html('0' + minuteCount);
                } else {
                    minutes.html(minuteCount);
                }
            }
        }, 1000);
    }

    if (mainStage.hasClass('record')) {

        setInterval(function() {
            counter--;
            if (counter >= 0) {
                countdown.html(counter);
            }
            if (counter === 0) {
                countdown.addClass('-invisible');
                video.attr('src', videoSrc + '&autoplay=1');
                backdrop.addClass('-invisible').attr('src', '/images/backdrop-hi.jpg');
                embed.removeClass('-invisible');
                timer.removeClass('-invisible');
                stop.removeClass('-invisible');
                camera.removeClass('-invisible');
                clearInterval(counter);
                chronometer();
            }

        }, 1000);
    }


    function loader() {
        load.removeClass('-invisible');
        mainStage.css('z-index', 200);
        var loading = setInterval(function() {
            loadCount++;

            if (loadCount <= 100) {
                loadBar.css('width', loadCount + '%')
                loadPercent.html(loadCount);

                if (loadCount < 30) {
                    loadMessage.html(messages[0]);
                } else if (loadCount < 60) {
                    loadMessage.html(messages[1]);
                } else if (loadCount < 96) {
                    loadMessage.html(messages[2]);
                } else if (loadCount < 100) {
                    loadMessage.html(messages[3]);
                } else if (loadCount === 100) {
                    loadMessage.html(messages[4]);
                    clearInterval(loading);
                    setTimeout(function(){
                        var url = window.location.href,
                        urlPathname = window.location.pathname,
                        newPath = '/participantes/',
                        newUrl = url.replace(urlPathname, newPath);

                        window.location.replace(newUrl);
                    }, 1000);
                }
            }

        }, 250);
    }

    stop.click(function(){
        clearInterval(startCron);
        video.attr('src', '');
        embed.addClass('-invisible');
        scene.addClass('-uploading');
        backdrop.removeClass('-invisible');

        loader();
    });
};

module.exports = Record;
