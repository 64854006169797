// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

global.$ = global.jQuery = require('jquery');
var Header = require('../_modules/header/header');
var VideoCard = require('../_modules/video-card/video-card');
var Home = require('./home');
var Record = require('./record');

$(function() {
    new Header();
    new Home();
    new Record();
    new VideoCard();
});
