'use strict';

// Constructor
var Header = function() {
    var header = $('.header');
    var trigger = $('.hamburguer');
    var legal = $('.legal');
    var legalTrigger = $('.menu-legal');
    var legalClose = $('.close-legal');

    trigger.on('click', function(){
        header.toggleClass('-open');
    });

    legalTrigger.click(function(){
        header.removeClass('-open');
        legal.removeClass('-invisible');
    });

    legalClose.click(function(){
        legal.addClass('-invisible');
    });
};

module.exports = Header;
