'use strict';

// Constructor
var Home = function() {
    var ctaFacebook = $('.-facebook'),
        cta = $('.-cta'),
        title = $('.title'),
        desc = $('.desc'),
        profile = $('.profile'),
        profileImg = $('.profile img'),
        invisibleClass = '-invisible',
        scene = $('.scene'),
        persona = {
            name: 'Sebastian',
            img: '/images/persona.png'
        },
        login; //Variable temporal para ejecutar el cambio de excena


    /**
        Sustituir y utilizar las funciones correspondientes
    **/
    function loggedIn(person) {
        var titleNewMsg = 'Participa del desafío Kem Xtreme';
        var descNewMsg = 'Hola' + person.name + ', ¿Estás listo para el #DesafíoKX? Intenta no pestañear.';
        var ctaNexText = '¡Estoy Listo!';
        var profileImgSrc = person.img;
        var nextPage = '/record';

        if (login) {
            title.html(titleNewMsg);
            desc.html(descNewMsg);
            cta.removeClass('-facebook');
            cta.html(ctaNexText);
            cta.attr('href', nextPage);
            profileImg.attr('src', profileImgSrc);
            profile.removeClass('-hidden');

            return 'success';
        } else {
            alert('Login error');
            return 'error';
        }
    }

    function facebookLogin(flag) {
        if ( flag === true ) {
            return true;
        } else {
            return false;
        }
    }

    ctaFacebook.click(function(){
        login = !login;
        if ( facebookLogin(true) === true ) {
            scene.addClass(invisibleClass);
            setTimeout(function(){
                loggedIn(persona);
                scene.removeClass(invisibleClass);
            }, 500);
        } else {
            alert('No se pudo conectar con facebook');
        }
    });

};

module.exports = Home;
