'use strict';

// Constructor
var VideoCard = function() {
    var videoCard = $('.video-card');
    var videoSrc;
    var playerContainer = $('.player');
    var player = $('.player iframe');
    var body = $('body');

    if (videoCard) {
        videoCard.each(function(){
            var $this = $(this);
            var trigger = $this.find('.img-placeholder');
            var close = $('.close');

            videoSrc = $this.data('video') + '&rel=0&showinfo=0&autoplay=1&controls=0';

            trigger.click(function(){
                body.addClass('-hideOverflow');
                playerContainer.removeClass('-invisible');
                player.attr('src', videoSrc)
            });

            close.click(function(){
                body.removeClass('-hideOverflow');
                playerContainer.addClass('-invisible');
                player.attr('src', '')
            });
        });
    }
};

module.exports = VideoCard;
